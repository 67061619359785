import React, { useRef } from "react";
import APIProvider, { useContactRequestAdd } from "../utils/api";

// Test of API - this file should be deleted before release to prod

// Used to build form
const fields = {
  name: ["Name"],
  email: ["E-mail address"],
  organizationName: ["Organisation Name"],
  phoneNumber: ["Phone Number"],
  message: ["Message", "TEXTAREA"],
  audienceType: ["Audience Type"],
  countryRegion: ["Region"],
  referredBy: ["Referred by"],
  accountFlag: ["Has an account:", "BOOLEAN"],
  accountNumber: ["Account Number"],
  newsletterSignup: ["Newsletter signup", "BOOLEAN"]
}

/*
  Fields used in mutation:

  source: String
  name: String!
  email: String!
  organizationName: String
  jobTitle: String              - This should be the audienceType
  phoneNumber: String!
  message: String!
  postCode: String
  referredBy: String
  newsletterSignup: Boolean
  countryRegion: String
  accountNumber: String         - "none" if accountFlag==false, "unknown" if accountFlag=true but no accountNumber given, or accountNumber
*/


// Sub Component

const TestComponent = () => {
  const refs = useRef({})
  const [ contactRequestAdd, { data, loading, error } ] = useContactRequestAdd();

  return(
    <div>
      <h1>Test API Form</h1>
      <form onSubmit={e => {
        e.preventDefault();

        let variables={
          ...Object.entries(fields).reduce((m, [k, v]) => ({...m, [k]: refs.current[k]?.value}), {}),
          source: process.env.GATSBY_CU_API_SOURCE_CONTACTUS
        }
        // store the audience type as Job Title
        variables["jobTitle"] = variables["audienceType"]
        delete variables["audienceType"]

        // encode the account flag and number as account number
        variables["accountNumber"] = variables["accountFlag"]==="true" ? (variables["accountNumber"] || "unknown" ) : "none"
        delete variables["accountFlag"]

        contactRequestAdd({ variables: variables })
      }}>
        <table>
          <tbody>
            { Object.keys(fields).map((e, i) => (
              <tr key={i}>
                <td style={{ textAlign: "right" }}>{fields[e][0]}:</td><td>
                {
                  fields[e][1] === "TEXTAREA" ?
                    <textarea ref={el => refs.current[e]=el} />
                  : fields[e][1] === "BOOLEAN" ?
                    <select ref={el => refs.current[e]=el}>
                      <option value="">Please Select</option>
                      <option value="true">True</option>
                      <option value="false">False</option>
                    </select>
                  :
                    <input ref={el => refs.current[e]=el} />
                }
              </td></tr>
            ))}
            <tr><td /><td>
              <input type="submit" value="Submit" />
            </td></tr>
            <tr>
              <td>{error ? "Error:" : data ? "Result:" : ""}</td><td><pre>
                {
                  loading ? "Loading ..." :
                  error ? JSON.stringify(error,null,2) :
                  data ? JSON.stringify(data,null,2) :
                  ""
                }
              </pre></td>
            </tr>
          </tbody>
        </table>
      </form>
    </div>
  )
}

// Main Component

const TestAPIPage = () => {
  return (
    <APIProvider>
      <TestComponent />
    </APIProvider>
  )
}

export default TestAPIPage;
